import Hero from '../components/Hero/Hero'
import { HiOutlineExternalLink } from 'react-icons/hi'

function Page401() {
  return (
    <div className="page-40X-wrapper">
      <Hero />
      <section className="page-40X">
        <p className="page-40X-subtitle">
          We apologize for the inconvenience, but we would like to inform you
          that you currently do not have a QCitizen ID.
        </p>
        <p className="page-40X-subtitle">
          We kindly request you to apply for a QCitizen ID first before using
          People's Corner.
        </p>
        <a
          className="external"
          href="https://quezoncity.gov.ph/qcitizen-guides/how-to-apply-for-a-qcitizen-id/"
          target="_blank"
          rel="noopener noreferrer"
        >
          HOW TO APPLY FOR A QCITIZEN ID
          <HiOutlineExternalLink />
        </a>
      </section>
    </div>
  )
}
export default Page401
