import { useContext } from 'react'
import { UserContext } from '../../../context'
import { Modal, Form } from 'react-bootstrap'
import { HiOutlineUserAdd } from 'react-icons/hi'
import {
  createCognitoUser,
  AddUserToGroup,
  GetUser,
} from '../../../utilities/Requests'
import { createUser } from '../../../database'
import { NotificationManager } from 'react-notifications'
import useAccount from '../../../hooks/useAccount'
import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Email from '../../Input/Email'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'
import Helper from '../../Password/Helper'
import Password from '../../Input/Password'
import Select from '../../Input/Select'

function Create({ departments, setUsers, violations }) {
  const { currentUser, currentUserGroups } = useContext(UserContext)
  const required = ['email', 'password', 'group']
  const {
    state,
    handleCreate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  } = useAccount()

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (checkInputValidity(required)) {
      try {
        const user = {
          ...state.account,
          department: state.account.department
            ? state.account.department
            : currentUser.attributes['custom:department'],
          departmentName: state.account.departmentName
            ? state.account.departmentName
            : currentUser.attributes['custom:department_name'],
        }

        const response = await createCognitoUser(user)

        const newCognitoUser = await GetUser(user.email)

        const newUser = await createUser({
          ...user,
          sub: newCognitoUser.Username,
          status: newCognitoUser.UserStatus,
          enabled: Boolean(newCognitoUser.Enabled),
        })

        await AddUserToGroup(state.account)

        setUsers((prevState) => [...prevState, newUser])
        NotificationManager.success(`${response.message}`)
        document.querySelector('#close').click()
      } catch (error) {
        console.log(error)
        setAlert({
          variant: 'danger',
          message: error.response?.data?.message
            ? error.response.data.message
            : 'Problems occurred while updating account. Please contact administrator',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlineUserAdd />} onClick={handleCreate}>
        Add
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="Add Account" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Select
              name="group"
              label="Role"
              onChange={handleInput}
              validated={state.validated}
              defaultValue={state.account.group}
              state={state.account.group}
              required={true}
            >
              <option value="" disabled>
                Select Role
              </option>
              {(currentUserGroups.find((x) => x === 'QCWatchAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <option value="QCWatchAdmin">QCWatch Admin</option>
              )}
              {(currentUserGroups.find((x) => x === 'QCActionAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <option value="QCActionAdmin">QCAction Admin</option>
              )}
              {currentUserGroups.find((x) => x === 'Admin') && (
                <option value="QCFoiAdmin">QCFOI Department Admin</option>
              )}
              {(currentUserGroups.find((x) => x === 'QCFoiAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <>
                  <option value="QCFoiApprover">QCFOI Approver</option>
                  <option value="QCFoiProcessor">QCFOI Processor</option>
                </>
              )}
            </Select>
            {['QCFoiAdmin', 'QCFoiApprover', 'QCFoiProcessor'].includes(
              state.account.group
            ) &&
              !currentUser.attributes['custom:department'] && (
                <Select
                  name="department"
                  label="Department/Office/Operating Unit"
                  onChange={handleInput}
                  validated={state.validated}
                  defaultValue=""
                  state={state.account.department}
                  required={true}
                >
                  <option value="" disabled>
                    Select Department
                  </option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </Select>
              )}
            {['QCWatchAdmin'].includes(state.account.group) &&
              !currentUser.attributes['custom:department'] && (
                <Select
                  name="department"
                  label="Department/Office/Operating Unit"
                  onChange={handleInput}
                  validated={state.validated}
                  defaultValue=""
                  state={state.account.department}
                  required={true}
                >
                  <option value="" disabled>
                    Select Department
                  </option>
                  {violations.map((violation) => (
                    <option key={violation.id} value={violation.qcdepartmentID}>
                      {
                        departments.find(
                          (x) => x.id === violation.qcdepartmentID
                        )?.name
                      }
                    </option>
                  ))}
                </Select>
              )}
            <Email
              name="email"
              label="Email"
              state={state.account.email}
              onChange={handleInput}
              validated={state.validated}
              required={true}
            />
            <Password
              name="password"
              label="Temporary Password"
              state={state.account.password}
              onChange={handleInput}
              validated={state.validated}
              required={true}
            />
            <Helper state={state.account.password} />
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="blue" type="submit" loading={state.loading}>
              {state.loading ? 'Adding' : ' Add'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Create
